<template>
  <section class="what-we-offer custom-section">
    <div class="container">
      <h2 class="title">{{ $t('whatWeOffer.title') }}</h2>
      <div class="content-wrapper">
        <div
          v-for="item in infoList"
          :key="item.id"
          class="card"
        >
          <div class="top">
            <div class="icon">
              <img
                :src="item.img"
                :alt="item.title"
              >
            </div>
            <div class="title">{{ item.title }}</div>
          </div>

          <div class="description">{{ item.description }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const { t } = useI18n();

const infoList = [
  {
    title: t('whatWeOffer.block1.title'),
    description: t('whatWeOffer.block1.text'),
    img: useAsset('img/what-we-offer/safety.png'),
    id: 1
  },
  {
    title: t('whatWeOffer.block2.title'),
    description: t('whatWeOffer.block2.text'),
    img: useAsset('img/what-we-offer/support.png'),
    id: 2
  },
  {
    title: t('whatWeOffer.block3.title'),
    description: t('whatWeOffer.block3.text'),
    img: useAsset('img/what-we-offer/economy.png'),
    id: 3
  }
];
</script>

<style scoped lang="scss">
.what-we-offer {
	& .content-wrapper {
		display: flex;
		gap: 120px;

		& > div {
			flex: 1;
		}
	}

	& .card {
		display: flex;
		flex-direction: column;
		align-items: center;

		& .top {
			align-items: center;
			margin-bottom: 30px;
			display: flex;
			gap: 36px;
		}

		& .icon {
			height: 110px;
			min-height: 110px;
			max-height: 110px;
			min-width: 110px;
			flex: 1;
			width: fit-content;
			display: flex;
			justify-content: center;
			align-items: center;

			& img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        object-position: center;
			}
		}

		& .title {
			color: var(--violet);
			text-align: center;
			font-size: 36px;
			font-style: normal;
			font-weight: 700;
			line-height: 110%;
			display: flex;
		}

		& .description {
			text-align: center;
			font-size: 20px;
			font-weight: 500;
			line-height: 110%;
			display: flex;

		}
	}
}

@media (max-width: 1200px) {
	.what-we-offer {
		& .content-wrapper {
      display: flex;
      flex-wrap: wrap;
			gap: 60px;

			& > div {
				flex: 1;

        &:last-child {
          flex: 0.5;
          margin: 0 auto;
        }
			}
		}
	}
}

@media (max-width: 1024px) {
	.what-we-offer {
		& .content-wrapper {
			gap: 30px;
      flex: wrap;

			& > div {
				flex: 1;
			}
		}
	}
}

@media (max-width: 1024px) {
	.what-we-offer {
		& .content-wrapper {
			gap: 20px;
			flex-wrap: wrap;

			& > div {
				flex: 1;

        &:last-child {
          flex: 0;
          margin: 0 auto;
        }
			}
		}
		& .card {
			& .title {
				font-size: 25px;
				line-height: 110%;
			}
    }
	}
}

@media (max-width: 860px) {
	.what-we-offer {
		& .content-wrapper {
			gap: 20px;
			flex-wrap: wrap;

			& > div {
				flex: 1;
			}
      & > div:last-child {
        flex: 1;
        margin: 0;
      }
		}
	}
}
@media screen and (max-width: 640px) {
  .what-we-offer  {
	  display: flex;

    & .content-wrapper {
      max-width: 100%;
      display: grid;
      grid-template-columns: repeat(1, 360px);
      grid-auto-rows: auto;
      row-gap: 58px;
      margin: 0 auto;
    }

	  & .card {
      & .top {
        width: 100%;
        align-items: center;
        margin-bottom: 30px;
        display: flex;
        gap: 36px;
      }

      & .icon {
        flex: 0;
      }

      & .title {
		max-width: 100%;
        font-size: 25px;
        line-height: 110%;
      }
	  }
  }
}

@media screen and (max-width: 450px) {
  .what-we-offer  {
    & .content-wrapper {
      grid-template-columns: minmax(200px, 1fr);
    }
  }
}
</style>
